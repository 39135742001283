<template>
  <div class="detail-achievements-container">
    <div class="content-container">
      <Spin size="large" fix v-show="spinShow"></Spin>
      <div v-if="total > 0 || spinShow">
        <AchievementItem :itemList="itemList"></AchievementItem>
      </div>
      <div class="no-results" v-else>
        <img :src="require('@/assets/qietu/results.png')" alt="">
        <p>暂无成果</p>
      </div>
      <Paging
        :total="total"
        :curPage="pageNo"
        :limit="10"
        @changePager="handlePager"
      ></Paging>
    </div>
    <Side :sideList="sideList" title="热门学者"></Side>
  </div>
</template>

<script>
import Side from "../components/Side.vue";
import AchievementItem from "../components/AchievementItem.vue";
import Paging from "@/components/paging/index.vue";
export default {
  components: {
    AchievementItem,
    Side,
    Paging
  },
  data() {
    return {
      itemList: [],
      sideList: [],
      pageNo: 1,
      total: 0,
      params: {
        id: this.$route.query.id,
      },
      spinShow: false
    };
  },
  created() {
    this.achievementListByCircleIdOrDiscussId({
      type: 2,
      circleId: this.$route.query.id,
      pageNo: 1,
      pageSize: 10,
    });
    this.getHotScholarList(this.params);
  },
  methods: {
    handlePager(page) {
      this.achievementListByCircleIdOrDiscussId({
        type: 2,
        circleId: this.$route.query.id,
        pageNo: page,
        pageSize: 10,
      });
    },
    async achievementListByCircleIdOrDiscussId(data) {
      this.spinShow = true
      const resp = await this.$apis.circleServe.achievementListByCircleIdOrDiscussId(data);
      this.itemList = resp.data && resp.data.list;
      this.pageNo = resp.data && +resp.data.pageNum
      this.total = resp.data && +resp.data.total
      this.spinShow = false
    },
    async getHotScholarList(data) {
      const resp = await this.$apis.circleServe.getHotScholarList(data);
      this.sideList = resp.data;
    },
  },
};
</script>

<style lang="scss" scoped>
.detail-achievements-container {
  display: flex;
}
.content-container {
  position: relative;
  padding: 29px;
  padding-top: 0;
  width: 1040px;
  margin-right: 40px;
  border: 1px dotted #999999;
  border-radius: 12px;
  .no-results {
    margin: 0 auto;
    padding-top: 30px;
    width: 500px;
    height: 400px;
    img {
      width: 100%;
    }
    p {
      text-align: center;
      font-size: 16px;
      color: #999999;
    }
  }
  .resultNum {
    font-size: 15px;
    color: #999999;
    font-weight: 400;
    span {
      color: #00a4ff;
    }
    margin-bottom: 14px;
  }
}

.side {
  width: 360px;
  margin: -60px 0 40px 40px;
  .cooperation {
    width: 360px;
    margin-bottom: 30px;
    background: #ffffff;
    border: 1px dotted #999999;
    border-radius: 6px;
    position: relative;
    padding: 30px;
    overflow: hidden;
    &::after {
      position: absolute;
      left: -1px;
      top: -1px;
      content: "";
      display: block;
      width: 100%;
      height: 4px;
      background-image: url("~@/assets/imgs/squre.png");
      background-size: 100% 100%;
    }
    .box {
      li {
        display: flex;
        margin: 30px 0;
        align-items: center;
        img {
          width: 60px;
          height: 60px;
          margin-right: 15px;
          border-radius: 50%;
        }
        .info {
          .name {
            font-size: 18px;
            color: #00a4ff;
          }
          .school {
            font-size: 14px;
            color: #999999;
            margin-top: 8px;
          }
        }
      }
    }
    .title {
      font-size: 22px;
      font-weight: 400;
      color: #00a4ff;
      margin-bottom: 25px;
    }
  }
}
</style>
